import { render, staticRenderFns } from "./RatingPink.vue?vue&type=template&id=607bf802&"
import script from "./RatingPink.vue?vue&type=script&lang=js&"
export * from "./RatingPink.vue?vue&type=script&lang=js&"
import style0 from "./RatingPink.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports