<template>
  <div class="adicionar-content">
    <router-link to="/">
      <img src="@/assets/icons/Forward.svg" alt="" />
    </router-link>

    <FormAdicionar></FormAdicionar>
  </div>
</template>

<script>
import FormAdicionar from '../components/Form/FormAdicionar.vue'

export default {
  components: {
    FormAdicionar
  },
  data() {
    return {
    }
  },
};
</script>

<style scoped>
.adicionar-content {
  padding: 24px;
}

.adicionar-content h5 {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.4px;

  color: #009987;
  margin-bottom: 32px;
}


</style>