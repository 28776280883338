<template>
  <div class="detail-container">
    <div
    v-if="contentData"
      class="display"
      :style="{'background-image': `url(${contentData.capas[0]})`}"
    >
      <router-link to="/">
        <img src="@/assets/icons/Forward.svg" alt="" />
      </router-link>
    </div>
    <div class="content">
      <div class="content-info">
        <div class="info">
          <h4 class="content-title">{{contentData.nome}}</h4>
          <p class="content-data">
            <span>{{contentData.ano_lancamento}}</span> &bull; <span>{{tipo==="Filmes"?contentData.duracao+" min":contentData.temporadas+" Temporada(s)"}}</span>
          </p>
          <p class="content-created">Direção</p>
          <p class="content-director">{{tipo==="Filmes"?contentData.direcao:contentData.criador}}</p>
          <div>
            <ul class="content-tags">
              <Tag :label="tag" v-for="(tag, index) of contentData.tags" :key="index"></Tag>
            </ul>
          </div>

          <div class="content-rating">
            <Rating :rating="notas"></Rating>
            <p class="comment" v-if="comentarios[0]">
              {{comentarios[0].comentario}}
              <br>
              <span>“{{comentarios[0].usuario?comentarios[0].usuario.nome:"Não encontrado"}}”</span>
            </p>
          </div>
        </div>
        <div class="picture">
          <img
            :src="contentData.capas[0]"
            alt=""
          />
        </div>
      </div>
      <div class="content-rating-mob">
        <Rating :rating="notas"></Rating>
        <p class="comment" v-if="comentarios[0]">
          {{comentarios[0].comentario}}
          <br>
          <span>“{{comentarios[0].usuario?comentarios[0].usuario.nome:"Não encontrado"}}”</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Rating from "../components/common/Rating/RatingPink.vue";
import Tag from '../components/common/Tag/Tag.vue'
import Filmes from '../services/filmes'
import Series from '../services/series'
import Comentarios from '../services/comentarios'
import Notas from '../services/notas'

export default {
  components: {
    Rating,
    Tag
  },
  data(){
    return{
      contentData: {},
      tipo: "",
      comentarios: [],
      notas: 0,
    }
  },
  mounted(){
    this.getData()
    this.tipo = this.$route.query.tipo
  },
  methods:{
    getData(){
      this.getRatingAndComments(this.$route.params.id)
      if(this.$route.query.tipo==="Filmes")
        Filmes.findOne(this.$route.params.id).then(response=>{
          this.contentData = response.dados
        })
      else
        Series.findOne(this.$route.params.id).then(response=>{
          this.contentData = response.dados
        })
    },
    getRatingAndComments(id){
      Comentarios.findByFilmeSerie(id).then(response=>{
        this.comentarios = response.dados
      })
      Notas.findByFilmeSerie(id).then(response=>{
        let notas = response.dados
        if(notas[0]){
        let count = 0
        this.notas = notas.map(nota=>{
          return count += nota.nota
        })/notas.length
        console.log(this.notas)
        }
      })
    }
  }
};
</script>

<style scoped>
.detail-container {
  width: 100%;
  height: 100vh;
}

.detail-container .display {
  background-position: center -200px;
  background-size: cover;
  position: relative;
}

.detail-container .display a {
  position: absolute;
  left: 32px;
  top: 60px;
}

.content-info {
  display: flex;
  padding: 30px 24px;
  border: 1px solid #c6c6c6;
}

.info{
  width: 70%;
}

.picture {
  width: 30%;
}

.content-info div:last-child img {
  max-width: 100%;
}

.content-rating {
  display: none;
}

.content-rating-mob {
  padding: 30px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */

  letter-spacing: -0.4px;

  color: #414141;
}

.content-data {
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  letter-spacing: 0.2px;

  color: #414141;
}

.content-created {
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  letter-spacing: 0.2px;

  color: #414141;
}

.content-director {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.4px;

  color: #414141;
}

.content-tags {
  display: flex;
}

.content-tags li {
  margin-right: 8px;
}

.comment {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #414141;
  margin-top: 30px;
}

.comment span {
  font-weight: 700;
}

@media screen and (min-width: 700px) {
  .content {
    padding: 0 20%;
  }

  .content-info {
    flex-direction: row-reverse;
    height: 100%;
    position: relative;
    top: -150px;
    background: #fff;
    border-radius: 20px;
    border: 0;
    box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.2);
    margin: 30px;
  }

  .info {
    padding-left: 30px;
    width: 60%;
    display: flex;
    flex-direction: column;
    
  }

  .picture {
    width: 40%;
  }


  .content-rating {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    width: 100%;
    margin-top: 25px;
  }


  .content-rating-mob {
    display: none;
  }

  .content-title {
    font-size: 1.6rem;
    margin-bottom: 15px;
  }
}
</style>