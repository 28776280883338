<template>
  <div class="home-container">
    <HeaderHome></HeaderHome>
    <SearchInput />
    <h2>Todos as Séries</h2>
    <Grid :dataContent="series"></Grid>
    <ButtonAdd :onClick="NewData"></ButtonAdd>
    <Menu></Menu>
  </div>
</template>

<script>
import HeaderHome from "../components/common/HeaderHome/HeaderHome.vue";
import Menu from "../components/common/Menu/Menu.vue";
import ButtonAdd from "../components/common/Button/ButtonAdd.vue";
import SearchInput from "../components/common/SearchInput/SearchInput.vue";
import Grid from "../components/Grid/Grid.vue";
import Series from "../services/series";

import { mapActions } from "vuex";

export default {
  components: {
    Menu,
    SearchInput,
    ButtonAdd,
    HeaderHome,
    Grid,
  },
  data() {
    return {
      series: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...mapActions({
      setIsLoading: "setIsLoading",
    }),
    getData() {
      this.setIsLoading(true);
      Series.findAll().then((response) => {
        this.series = response.dados;
        this.setIsLoading(false);
      });
    },
    NewData() {
      this.$router.push("/adicionar");
    },
  },
};
</script>

<style scoped>
h2 {
  margin-bottom: 19px;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.4px;
  color: #009987;
}

.home-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.input-wrapper {
  position: relative;
  margin-bottom: 20px;
}

.input-wrapper img {
  position: absolute;
  right: 5%;
  top: 30%;
}

@media screen and (min-width: 700px) {
  .home-container {
    margin-top: 95px;
    padding: 0 20%;
  }
}
</style>