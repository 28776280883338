<template>
  <div class="grid">
    <div class="card" v-for="content of dataContent" :key="content._id">
      <router-link :to="'/detalhe/'+content._id">
        <img
          :src="content.capas[0]"
          :alt="content.nome"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dataContent"],
  data(){
    return {
      filmes: []
    }
  },
};
</script>

<style scoped>
.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
}

.card {
  width: calc(50% - 16px);
  overflow: hidden;
}

.card img {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (min-width: 700px) {
.card {
  width: calc(25% - 16px);
  height: fit-content;
}
}
</style>