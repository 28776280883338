<template>
  <div class="home-container">
    <HeaderHome></HeaderHome>
    <SearchInput></SearchInput>
    <h2>Sua busca por " {{ search }} ":</h2>

    <div v-if="content.length === 0">
      <h3>Não encontramos resultados para sua busca...</h3>
    </div>
    <Grid v-else :dataContent="content"></Grid>
    <ButtonAdd :onClick="NewData"></ButtonAdd>
    <Menu></Menu>
  </div>
</template>

<script>
import HeaderHome from "../components/common/HeaderHome/HeaderHome.vue";
import Menu from "../components/common/Menu/Menu.vue";
import ButtonAdd from "../components/common/Button/ButtonAdd.vue";
import SearchInput from "../components/common/SearchInput/SearchInput.vue";
import Grid from "../components/Grid/Grid.vue";

import Filmes from "../services/filmes";
import Series from "../services/series";

import { mapActions } from "vuex";

export default {
  components: {
    Menu,
    ButtonAdd,
    HeaderHome,
    Grid,
    SearchInput,
  },
  data() {
    return {
      filmes: [],
      series: [],
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    search() {
      return this.$route.query.termo.trim();
    },
    content() {
      return [...this.filmes, ...this.series].filter((item) =>
        item.nome.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    ...mapActions({
      setIsLoading: "setIsLoading",
    }),
    getData() {
      this.setIsLoading(true);
      
      Filmes.findAll().then((response) => {
        this.filmes = response.dados;
      });
      Series.findAll().then((response) => {
        this.series = response.dados;
      });

      setTimeout(() => {
        this.setIsLoading(false);
      }, 1000);
    },
    NewData() {
      this.$router.push("/adicionar");
    },
  },
};
</script>

<style scoped>
h2 {
  margin-bottom: 19px;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.4px;
  color: #009987;
}

.home-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

@media screen and (min-width: 700px) {
  .home-container {
    margin-top: 95px;
    padding: 0 20%;
  }
}
</style>