<template>
  <div class="rating-container">
    <StarRating
      :rating="rating"
      border-color="#FF7FA8"
      inactive-color="#fff"
      active-color="#FF7FA8"
      :rounded-corners="true"
      :border-width="3"
      :show-rating="false"
      :read-only="true"
    />
  </div>
</template>

<script>
import StarRating from "vue-star-rating";

export default {
  components: {
    StarRating,
  },
  props: ['rating']
};
</script>

<style>
.vue-star-rating-rating-text {
  display: none;
}

.rating-container {
  display: flex;
  justify-content: center;
}
</style>